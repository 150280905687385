/*! responsive-nav.js 1.0.39 by @viljamis */

.nav-collapse ul {
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
  list-style: none;
}

.nav-collapse li {
  width: 100%;
  display: block;
}

.js .nav-collapse {
  clip: rect(0 0 0 0);
  max-height: 0;
  position: absolute;
  display: block;
  overflow: hidden;
  zoom: 1;
}

.nav-collapse.opened {
  max-height: 9999px;
}

.nav-toggle, .nav-toggle-main {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

@include tablet {
  .js .nav-collapse {
    position: relative;
    li {
      width: auto;
    }
  }
  .js .nav-collapse.closed {
    max-height: none;
  }
  .nav-toggle, .nav-toggle-main {
    display: none;
  }
}

//responsive nav behaviour
//this sets the nav ul as an evenly spaced horizontal line of links
//uncomment to use

@include tablet {
  div#nav {
    nav {
      ul {
        text-align:justify;
        li {
          display:inline-block;
        }
      }

      ul:after {
          content: '';
          width: 100%;
          display: inline-block;
          height: 0;
      }
    }
  }
}

//burger mobile nav
//modify /images/burger.png and burger.svg to suit site colours

.nav-toggle-wrap a span {
    background-image: url(/images/burger.png);
    background-image: url(/images/burger.svg);
    @include bgimage;
    @include hidetext;
    width: 47px;
    height: 26px;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
}
