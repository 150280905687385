.form {
  @include container;
  margin-bottom: 2em;
  position: relative;

  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: mix($blue, $lightBlue, 40%);
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: mix($blue, $lightBlue, 40%);
    opacity:  1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: mix($blue, $lightBlue, 40%);
    opacity:  1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: mix($blue, $lightBlue, 40%);
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: mix($blue, $lightBlue, 40%);
  }

  &__hidden {
    //display: none;
  }

  &__cancel {
    cursor: pointer;
    font-family: arial;
    font-weight: bold;
  }

  &__delete {
    position: absolute;
    top: 0;
    right: 0;
  }

  h2 {
    margin-top: 2em;
    margin-bottom: 0;
    font-size: 1.3em;
  }

  label {
    display: block;
    margin-bottom: 0.5em;
    font-weight: bold;
  }

  input[type=text],
  input[type=text]:disabled,
  input[type=number],
  select,
  textarea {
    -webkit-opacity: 1;
    width: 100%;
    border: 0;
    border-radius: 0px;
    background: $lightBlue;
    //font-size: 1.3em;
    padding: 0.3em 0.5em;
    outline: none;
  }

  textarea {
    height: 10em;
  }

  &__column {
    margin-top: 1em;
    width: 100%;
    &:last-child {
      @include last;
    }
    &--full {
      @include full;
    }
    &--third {
    }
  }

  &__row {
    @include container;
    margin-bottom: 1em;
    &--evens {
      .form {
        &__column {
          @include tablet {
            @include gallery(3 of 6);
          }
          @include desktop {
            @include gallery(4);
          }
        }
      }
    }
    &--odds {
      .form {
        &__column {
          @include tablet {
            @include gallery(2 of 6);
          }
        }
      }
    }
  }

  &__buttons {
    @include container;
    text-align: center;
  }

  &__button {
    background: $orange;
    border: 0;
    border-radius: 3px;
    color: #fff;
    text-transform: uppercase;
    padding: 0.3em 1.5em;
    font-size: 1.3em;
    &:disabled {
      background: #ccc;  
    }
  }

}
