.search {
  @include container;
  padding-top: 5em;
  @include tablet {
    padding-top: 8em;
  }
}

a#definitions,
a#popup-back {
  cursor: pointer;
}

.help {
  text-align: center;
  font-size: 0.9em;
  margin-bottom: 0;
  margin-top: 2em;
  a {
    color: $orange;
  }
}
