.results {
  @include container;

  &__header {
    @include container;
    padding-top: 5em;
    @include tablet {
      padding-top: 8em;
    }
  }

  &__text {
    @include desktop {
      @include span(5.5 of 9);
    }
  }

  &__options {
    @include desktop {
      @include span(3 of 9);
      @include last;
      margin-top: 2em;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      margin-bottom: 0.5em;
      a {
        display: block;
      }
    }
  }
}

.result {
  @include tablet {
    @include gallery(4);
  }
  @include desktop {
    @include gallery(3 of 9);
  }
  display: block;
  color: #fff;
  text-decoration: none;
  margin-bottom: gutter(9);
  &__thumb {
    height: 0;
    padding-top: 65%;
    background: $lightBlue;
    background-size: cover;
  }
  &__title {
    background: $orange;
    color: #fff;
    padding: 0.3em 0.5em;
    margin: 0;
  }
}
