.news {
  @include container;
}

.articles {
  @include container;
  padding: 0 20px;
  margin-top: 20px;

  h2 {
  }

  p {
    color: $dark;
  }

  &__video {
    @include desktop {
      @include span(4 of 6);
    }
  }

  &__news {
    @include desktop {
      @include span(2 of 6);
      @include last;
    }
  }
}

.story {

  padding-bottom: 1em;
  border-bottom: 1px solid $lightBlue;
  display: block;

  &__thumb {
    height: 0;
    padding-top: 65%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  &__date {
    color: #aaa;
  }

  &__summary {
    color: $orange;
  }

  &__text {
    h3 {
      margin-bottom: 0;
    }
    h4 {
      margin-top: 0;
      margin-bottom: 0;
      color: $dark;
    }
  }
}
