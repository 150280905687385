.table {
  @extend %container;
  margin-bottom: 2em;

  .highlighted {
    border: 1px solid green;
  }

  &__column {
    @include susy-media(0 60em) {
      font-weight: bold;
      &:before { font-weight: normal }
      &--ref {
        font-size: 1.2em;
        font-weight: bold;
        margin-bottom: 0.3em;
        color: $blue;
      }
      &--length:before {
        content: 'Length (m): ';
      }
      &--depth:before {
        content: 'Depth below CD (m): ';
      }
      &--openarea:before {
        content: 'Open area (m2): ';
      }
      &--shedarea:before {
        content: 'Shed area (m2): ';
      }
      &--general:before {
        content: 'General load capacity (t/m2): ';
      }
      &--heavy:before {
        content: 'Heavy lift capacity (t/m2): ';
      }
      &--primary:before {
        content: 'Primary use: ';
      }
    }
    @include desktop {
      font-weight: normal;
      float: left;
      width: 11%;
      text-align: center;
      position: relative;
      min-height: 5em;
      span {
        //position: absolute;
        //border-right: 1px solid #fff;
        //height: 100%;
        padding: 1em;
        margin: 0.5px;
        width: 100%;
        display: block;
      } 
      &--primary {
        width: 20%;
      }
      &:last-child {
        float: right;
      }
    }
  }

  &__row {
    @extend %container;
    display: block;
    @include susy-media(0 60em) {
      margin-top: 1em;
    }
    @include desktop {
      &:nth-child(2n) {
        background-color: $lightBlue;
      }
    }
    &--header {
      @include susy-media(0 60em) {
        display: none;
      }
      background: $blue;
      .table__column {
        color: #fff;
      }
    }
    &--match {
      background-image: url(/images/check.png);
      background-size: 1.5em;
      background-position: 0.3em 0.5em;
      background-repeat: no-repeat;
    }
  }

  a.table__row {
    &:hover {
      .table__column {
        background: #d0a979;
        color: #fff;
      }
    }
  }

}
