.buttons {
  @include container; 
  list-style: none;
  margin: 1em 0;
  padding: 0;
}

.button {
  background: $blue;
  font-size: 1.3em;
  color: #fff;
  padding: 0.3em 2em;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  border: 0;
  background: $orange;
  border: 2px solid #fff;
  border-radius: 0.2em;
  font-weight: 600;
  outline: none;
  @include tablet {
    display: inline-block;
  }
  &--refine {
    background: $blue;
  }
  &--export {
    background: lighten($blue, 10%);
  }
  &--download {
    background-image: url(/images/download-icon.svg);
    background-position: center right 0.5em;
    background-repeat: no-repeat;
    background-size: 1em;
    padding-right: 3em;
    text-transform: none;
    cursor: pointer;
    transition: 0.1s all ease;
  } 
  &--wait {
    background-color: rgba($orange, 0.6);
    transition: 0.1s all ease;
    transform: scale(1) !important;
  }
}
