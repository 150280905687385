.logo {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  &__image {
    height: 0;
    padding-top: 100%;
    display: block;
    &--sep {
      background-image: url(/images/sep-logo.png);
      background-image: url(/images/sep-logo.svg);
    }
  }
}
