.submenus {
  margin: 1em auto;
  @include tablet {
    display: flex;
    align-items: flex-end;
    @include container;
    margin-top: 0;
  }
}

.submenu {
  @include desktop {
    @include span(4);
  }
  ul {
    @extend %noListStyle;
    @include tablet {
      @include container;
    }
  }
  li {
    @include susy-media(0 40em) {
      border-top: 1px solid #ccc;
      width: 100%;
    }
    display: inline-block;
    @include tablet {
      margin-right: 2em;
    }
  }
  a {
    font-weight: bold;
    font-size: 1.3em;
    @include susy-media(0 40em) {
      padding: 0.5em 3%;
      display: block;
    }
    &.active {
      color: $orange;
    }
  }
  &--extra {
    @include tablet {
      @include last;
    }
    text-align: right;
    li {
      margin-right: 0;
      border-top: 0;
      //margin-left: 2em;
      a {
        @include tablet {
          width: 15em;
        }
        margin: 1em 1em 0 1em;
      }
    }
  }
}
