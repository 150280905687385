@mixin hidetext() {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

@mixin bgimage() {
	background-repeat:no-repeat;
	background-size:contain;
}

.header {
  @include container;
  display: flex;
  align-items: center;

  &__title {
    @include span(6);
    font-size: 0.9em;
    @include tablet {
      @include span(4);
      font-size: 1em;
    }
    display: flex;
    align-items: center;
    //display: block;
    text-decoration: none;
    color: #fff;
  }
  &__actions {
    @include span(4);
    @include last;
    text-align: right;
    a {
      text-decoration: none;
      color: #fff;
    }
  }

  &__titlelogo {
    height: 0;
    padding-top: 3em;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(/images/sep-logo-white.svg);
    width: 3em;
    display: inline-block;
    margin-right: 1em;
  }

  &__badges {
    @include span(4);
    @include last;
    .badge {
      @include span(3 of 9);
      &:last-child {
        @include last;
      }
    }
  }

}
