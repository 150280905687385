.homesearch {
  @extend %container;
  padding-bottom: 4em;
  @include susy-media(0 40em) {
    padding: 0 3% 2em 3%;
  }
  background: $blue;
  &__heading {
    h2 {
      font-size: 1.5em;
      font-weight: normal;
      max-width: 15em;
      margin-top: 1em;
      span {
        //display: block;
      }
    }
    @include tablet {
      h2 {
        margin-top: 0;
        font-size: 1.8em;
      }
      @include span(3.5);
    }
  }
  &__text {
    @include container;
  }
  &__body {
    @include tablet {
      @include span(4.5);
      @include last;
    }
  }
  &__input {
    position: relative;
    z-index: 2;
    display: block;
    border: 4px solid $midBlue;
    padding: 1em;
    width: 100%;
    border-radius: 3em;
    background-image: url(/images/search-icon.svg);
    background-position: center left 1em;
    background-repeat: no-repeat;
    background-size: 2em;
    padding-left: 4em;
    color: $dark;
    outline: none;
    transition: 0.3s linear all;
    &--open {
      background-color: #FEFFE1 !important;
      transition: 0.1s linear all;
      //border-color: #fff;
    }
  }
  &__form {
    @include container(40em);
    margin-top: 2em;
  }
}
