.hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 3%;
  padding-left: 3%;
  padding-right: 3%;
  //max-height: 69vh;
  @include tablet {
    padding-top: 7em;
  }
  &--dim {
    &:before {
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      //z-index: 1;
      top: 0;
      left: 0; 
      background: rgba(#000, 0.3);
    }
  }
  h1 {
    margin: 0 0 0.5em 0;
    max-width: 13em;
  }
  &__inner {
    @extend %container;
    padding: 6em 0 8%;
    color: #fff;
    position: relative;
    z-index: 2;
    min-height: 25em;
    @include tablet {
      padding-top: 4em;
    }
  }
  &__text {
    p {
      @include tablet {
        font-size: 1.1em;
      }
    }
    .buttons {
      padding-top: 1em;
    }
    @include tablet {
      @include span(6);
    }
    @include desktop {
      padding-right: 5em;
      @include span(4);
    }
    &--dark {
      color: $dark;
    }
  }
  &__logos {
    @include tablet {
      @include span(2);
      @include last;
    }
    @include desktop {
      @include span(4);
      @include last;
    }
    text-align: center;
    .logo {
      width: 30%;
    }
  }
}
