@import "httpdocs/public/bower_components/susy/sass/susy";   

$susy: (
	columns: 8,
	gutters: (1/4),
	global-box-sizing: border-box,
	container: 1280px
	
);


@mixin tablet() {
	@include susy-breakpoint(40em, 8) {  
		@content;
	}
}

@mixin desktop() {
	@include susy-breakpoint(60em, 8) {
		@content;
	}
}

@mixin large() {
	@include susy-breakpoint(80em, 8) {
		@content;
	}
}
