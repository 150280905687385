.badge {
  height: 0;
  padding-top: 3em;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  &--sep {
    background-image: url(/images/sep-logo-white.svg);
  }
  &--se {
    background-image: url(/images/se-logo-white.svg);
  }
  &--hie {
    background-image: url(/images/hie-logo-white.svg);
  }
  &--bpa {
    background-image: url(/images/bpa-logo-white.svg);
  }
}

