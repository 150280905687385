@import "core";
@import "icons";
@import "header";
@import "badges";
@import "responsive-nav";
@import "forms";
@import "formctrl";
@import "buttons";
@import "search";
@import "homesearch";
@import "results";
@import "tabs";
@import "page";
@import "grid";
@import "table";
@import "submenus";
@import "hero";
@import "magnific";
@import "logos";
@import "articles";

html {
  color: $blue;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
*, *:before, *:after {
  box-sizing: border-box;
}

.portmap {
}

a {
  color: $blue;
  text-decoration: none;
} 

.na {
  opacity: 0.5;
}

div#map {
	width:100%;
	height:0;
  padding-top: 60%;
	@include desktop {
		 
		 height:400px;
	}
}

main {
  padding-bottom: 2em;
}

.copy {
  @include container;
}

.outer {
  background: #feffff;
  padding: 0 3%;
  &--header {
    padding: 3%;
    color: #fff;
    background: rgba($dark, .75);
    border-bottom: 2px solid $orange; 
    font-weight: 600;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    @include tablet {
      padding: 2em 3%;
      background: rgba($dark, .75);
    }
  }
  &--wrap {
    background-image: url(/images/wave-light.svg);
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: top right;
    background-size: 250%;
    padding-top: 5%;
    margin-top: -17%;
    position: relative;
    //z-index: 20;
    background-size: 200%;
    background-position: top right;
    padding-top: 17%;
    padding-left: 0;
    padding-right: 0;
  }
  &--homesearch {
    background-image: url(/images/wave-dark.svg);
  }
  &--port {
    background-image: url(/images/wave-white.svg);
  }
  &--homesearch,
  &--port {
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 193%;
    background-position: top right;
    padding-top: 9%;
    padding-left: 0;
    padding-right: 0;
    margin-top: -4.5%;
    background-color: transparent;
    color: #fff;
    @include susy-media(0 40em) {
      //background: $blue;
    }
  }
  &--search, &--top {
    //border-bottom: 2px solid $orange; 
    //margin-bottom: 2em;
  }
  &--submenus {
    @include tablet {
      border-bottom: 2px solid $orange; 
    }
    margin-bottom: 2em;
    @include susy-media(0 40em) {
      padding: 0;
    }
  }
  &--portsmap {
    @include susy-media(0 40em) {
      padding-top: 5%;
      padding-left: 10%;
      padding-right: 10%;
      background: $blue;
    }
  }
  &--footer-wrap {
    background-image: url(/images/wave-combo.svg);
    background-size: 200%;
    background-position: top right; 
    background-repeat: no-repeat;
    padding-top: 12%;
    padding-left: 0;
    padding-right: 0;
  }
  &--footer {
    background: $blue;
  }
}

.b-loaded {
  padding-bottom: 0;
  height: auto;
}
.b-lazy {
        -webkit-transition: opacity 500ms ease-in-out;
           -moz-transition: opacity 500ms ease-in-out;
             -o-transition: opacity 500ms ease-in-out;
                transition: opacity 500ms ease-in-out;
                 max-width: 100%;
    }
    .b-lazy.b-loaded {
                   opacity: 1;
    }

