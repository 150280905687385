.page {
  @extend %container;
  color: $grey;

  h2, h3 {
    font-size: 1.1em;
    margin-bottom: 0.2em;
    margin-top: 2em;
  }
  p, ul {
    margin-top: 0;
  }

  &__main {
    @include desktop {
      @include span(3 of 4);
    }
  }

  &__image {
    background: $lightBlue;
    height: 0;
    padding-bottom: 60%;
    width: 100%;
  }

  &__side {
    @include desktop {
      @include span(1 of 4);
      @include last;
    }

    h4 {
      margin-bottom: 0em;
      font-size: 1.1em;
      margin-top: 2rem;
    }
    p {
      margin-top: 0;
    }
  }

  &__sidemap {
    background: #fff;
    background-size: contain;
    height: 0;
    padding-top: 140%;
    background-repeat: no-repeat;
  }

  &--tabbed {
    display: none;
    &.active {
      display: block;
    }
  }

  &--form {
    @include container(50em);
    position: relative;
  }
}
