.formctrl {

  position: relative;

  &__bg {
    background: rgba(#fff, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__label {
    position: relative;
    z-index: 2;
    margin-bottom: 0;
    color: #fff;
    background: $blue;
    border-radius: 0.5em 0.5em 0 0;
    padding: 0.5em 1em;
    max-width: 20em;
  }

  &__hidden {
    display: none;
  }

  &__dummy {
    display: none;
    &--hasval {
      color: $green;
      font-weight: bold;
    }
  }
  &__wrap {
    position: absolute;
    top: 0;
    left: 0;
    //width: 100%;
    max-width: 20em;
    border: 1px solid mix($blue, $lightBlue, 30%);
    padding: gutter();
    background: #fff;
    z-index: 2;
    input {
      border: 1px solid !important;
    }
  }

  &--closed {
    .formctrl {
      &__wrap {
        display: none;
      }
      &__dummy {
        display: block;
      }
    }
  }

  &__steps {
    @include container;
    padding-top: gutter();
  }

  &__button {
    padding: 1em;
    text-align: center;
    border-radius: 0.5em;
    cursor: pointer;
    margin-top: gutter();
    background: #bbb;
    color: #fff;
    @include span(4);
    &--last {
      @include last;
    }
  }

  &__step {
    background: $blue;
    color: #fff;
    &--disabled {
    }
  }

  &__confirm {
    background: #1b7934;
    color: #fff;
  }

  .is-disabled {
    opacity: 0.5;
  }

}
