@import "normalize";
@import "grids";
@import "colours";

%container {
  @include container;
}

%noListStyle {
  list-style: none;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'nudista-web', arial;
}
