.grid {
  @extend %container;
  margin-bottom: 2em;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  list-style: none;
  &__item {
    @include tablet {
      @include gallery(1 of 2);
    }
    h3 {
      font-size: 1em;
      margin-bottom: 0.3em;
    }
  }
  &__ext {
    @include span(1);
    span {
      display: block;
      height: 0;
      padding-top: 100%;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
    &--pdf, &--doc, &--docx {
      span {
        background-image: url(/images/document.svg);
      }
    }
    &--jpg, &--png, &--gif, &--bmp, &--tiff {
      span {
        background-image: url(/images/image.svg);
      }
    }
  }
  &__text {
    @include span(7);
    @include last;
    h3 {
      margin-top: 0;
    }
  }
  &--media {
    .grid__item {
      margin: 1em 0;
    }
  }
}
