.tabs {
  @include container;
  margin: 2em auto 1em;
}

.tab {
  border: 1px solid $blue;
  padding: 0.3em 0 0.3em 1em;
  float: left;
  margin-right: 2em;
  display: block;
  text-decoration: none;
  color: $blue;
  margin-bottom: 1em;
  &:after {
    background: $blue;
    color: #fff;
    font-weight: bold;
    content: 'x';
    padding: 0.3em 0.5em;
    margin-left: 1em;
  }
  &:hover {
    border-color: red;
    &:after {
      background: red;
    }
  }
}
